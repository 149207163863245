import app from "../recess_app";
import { Controller } from "@hotwired/stimulus";

// NOTE: On desktop, the background is shown on hover. But on mobile, hovering is awkward.
// Therefore this controller exists to fade the background in and out on mobile in response
// to the user's scroll position.
export default class extends Controller {
  static targets = ["background"];

  connect() {
    if (!this.hasBackgroundTarget) {
      return;
    }

    this.observer = new IntersectionObserver(
      ([{ intersectionRatio }]) => {
        // TODO: update event bus brekapoints
        if (app.state.breakpoint !== "sm") {
          return;
        }

        const cubicEased = Math.pow(intersectionRatio, 3);
        this.backgroundTarget.style.setProperty("opacity", cubicEased);
      },
      {
        threshold: Array.from({ length: 100 }).map((_, i) => i / 100),
      }
    );

    this.observer.observe(this.backgroundTarget);
  }

  disconnect() {
    if (!this.observer) {
      return;
    }

    this.observer.disconnect();
  }
}
