import { Controller } from "@hotwired/stimulus";
import { fadeOutElement, fadeInElement, fadeOutElements, fadeInElements } from "../utilities";

export default class extends Controller {
  static targets = [
    "slide",
    "caption",
    "nextButtonLabel",
    "previousButtonLabel",
    "announcement",
  ];
  static values = {
    index: Number,
    maxIndex: Number,
    backgroundColor: String,
    foregroundColor: String,
  };

  goTo(index) {
    if (typeof index !== "number" || index < 0 || index > this.maxIndexValue) {
      return;
    }

    const targetSlide = this.slideTargets[index];
    if (!targetSlide) {
      return;
    }

    this.indexValue = index;
    this.backgroundColorValue = targetSlide.dataset.backgroundColor;
    this.foregroundColorValue = targetSlide.dataset.foregroundColor;
  }

  indexValueChanged(index, previousIndex) {
    const previousSlide = this.slideTargets[previousIndex];
    const previousCaption = this.captionTargets[previousIndex];
    const currentSlide = this.slideTargets[index];
    const currentCaption = this.captionTargets[index];

    (previousSlide
      ? fadeOutElements({ elements: [previousSlide, previousCaption] })
      : Promise.resolve()
    ).then(() => fadeInElements({ elements: [currentSlide, currentCaption] }));

    if (this.hasNextButtonLabelTarget) {
      this.nextButtonLabelTarget.textContent = `Go to slide #${
        this.nextIndex + 1
      }`;
    }
    if (this.hasPreviousButtonLabelTarget) {
      this.previousButtonLabelTarget.textContent = `Go to slide #${
        this.previousIndex + 1
      }`;
    }
    if (this.hasAnnouncementTarget) {
      this.announcementTarget.textContent = index + 1;
    }
  }

  backgroundColorValueChanged(backgroundColor) {
    this.element.style.setProperty("background-color", backgroundColor);
  }

  foregroundColorValueChanged(color, previousColor) {
    this.element.classList.remove(`text-${previousColor}`);
    this.element.classList.add(`text-${color}`);
  }

  next() {
    if (typeof this.nextIndex !== "number") {
      return;
    }

    return this.goTo(this.nextIndex);
  }

  previous() {
    if (typeof this.previousIndex !== "number") {
      return;
    }

    return this.goTo(this.previousIndex);
  }

  get nextIndex() {
    if (this.maxIndexValue === 0) {
      return false;
    } else if (this.indexValue === this.maxIndexValue) {
      return 0;
    } else {
      return this.indexValue + 1;
    }
  }

  get previousIndex() {
    if (this.maxIndexValue === 0) {
      return false;
    } else if (this.indexValue === 0) {
      return this.maxIndexValue;
    } else {
      return this.indexValue - 1;
    }
  }
}
