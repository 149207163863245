import app from "../recess_app";

/*
  Icons can be rendered at any size. Many of our icons are single weight line drawings.
  When an SVG is scaled, the strokes are scaled with it — even if they are styled with
  absolute values (like `stroke-width="2px"`). 

  This script applies `stroke-width` styles to all SVG children w/ a `stroke` property,
  such that the line weight is always rendered at 1px.

  Hypothetically this could be done with CSS, but it's significantly easier to do it with JS.
*/
const resize = function resize() {
  [...document.querySelectorAll('svg')].forEach((svg) => {
    const { width: renderedWidth, height: renderedHeight } = svg.getBoundingClientRect();
    const nativeWidth = svg.getAttribute('width');
    const nativeHeight = svg.getAttribute('height');
    const scale = Math.sqrt((renderedWidth * renderedHeight) / (nativeWidth * nativeHeight));
    const strokeWidth = 1 / scale;
  
    [...svg.querySelectorAll('[stroke]')].forEach((strokedElement) => {
      strokedElement.style.setProperty('stroke-width', `${strokeWidth}`);
    });
  })
};

export const svgs = {
  init: () => {
    app.addEventListener('page-load', resize);
    app.addEventListener('resize', resize);
  },
};
