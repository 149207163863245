/*
  This controller is used to expanded the clickable area of a link.
  It is intended to prevent a component from having multiple anchor tags
  pointing to the same URL. Such a pattern has been flagged in previous
  accessibility audits.

  This controller allows for an element to trigger a click on a separate link.
  The link to be clicked is specified by the `data-expanded-link-id` attribute.
  If the attribute is not present, the first link within the element will be clicked.

  Please use the actual anchor tag to contain the most relevant link text.

  This controller is compatible with the `button-link` controller.

  Example:
    <div
      data-controller="expanded-link-area"
      data-action="mousedown->expanded-link-area#mouseDown mouseup->expanded-link-area#mouseUp"
      data-expanded-link-id="my-link"
    ></div>
    <a id="my-link" href="/my-link">This is the main link text.</a>
  
  Example:
    <%= tag.div(
      data: {
        controller: "expanded-link-area",
        action: "mousedown->expanded-link-area#mouseDown mouseup->expanded-link-area#mouseUp",
        expanded_link_id: "my-link"
      }
    ) do %>
      <%= link_to "This is the main link text.", "/my-link", id: "my-link" %>
    <% end %>

  TODO: This requires the element to add a lot of data attributes. Is there a more terse way to do this?
  TODO: Deprecate BlockLevelLink and replace it with this.
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.element.dataset.expandedLinkId) {
      this.link = document.getElementById(this.element.dataset.expandedLinkId);
    } else {
      this.link = this.element.querySelector(
        "a[href], button[data-controller='button-link'], [data-expanded-link-area-target]"
      );
    }
  }

  mouseDown() {
    if (!this.link) {
      return;
    }
    this.mouseDownTime = new Date().getTime();
  }

  mouseUp() {
    if (!this.link) {
      return;
    }

    if (!this.mouseDownTime) {
      return;
    }

    const currentTime = new Date().getTime();
    if (currentTime - this.mouseDownTime < 200) {
      this.link.click();
    }

    this.mouseDownTime = null;
  }
}
