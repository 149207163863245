import app from "../recess_app";
import Component from "./component";
import { animateProperty } from "../utilities/animation";

export class ResponsiveImage extends Component {
  get defaultState() {
    return { highResLoaded: false };
  }

  setUpElements() {
    super.setUpElements();

    const { element } = this.props;
    this.elements.element = element;
    this.elements.lowRes = element.querySelector(
      ".js-responsive-image__low-res"
    );
    this.elements.lowResImg = this.elements.lowRes.querySelector("img");
    this.elements.highRes = element.querySelector(
      ".js-responsive-image__high-res"
    );
    this.elements.highResImg = this.elements.highRes.querySelector("img");
  }

  setUpEvents() {
    super.setUpEvents();

    if (this.elements.highResImg.complete) {
      this.switchToHighRes();
    } else {
      this.elements.highResImg.addEventListener('load', this.switchToHighRes.bind(this));
    }
  }

  switchToHighRes() {
    return this.update({
      highResLoaded: true,
    }).then(() => {
      this.elements.lowResImg.parentElement.removeChild(this.elements.lowResImg);
    });
  }

  render(update) {
    const promises = [];

    if (update.hasOwnProperty("highResLoaded")) {
      promises.push(this.renderHighResImage());
    }

    return Promise.all(promises);
  }

  renderHighResImage() {
    if (!this.state.highResLoaded) {
      return;
    }

    this.elements.lowRes.classList.remove("relative");
    this.elements.lowRes.classList.add("absolute");
    this.elements.highRes.classList.remove("absolute");
    this.elements.highRes.classList.add("relative");
    this.elements.highRes.classList.remove("opacity-0");
    this.elements.highRes.classList.add("opacity-100");

    return animateProperty({
      element: this.elements.lowRes,
      property: "opacity",
      value: 0,
    }).then(() => {
      this.elements.lowRes.style.setProperty("display", "none");
    });
  }
}

export const responsiveImages = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(".js-responsive-image");

      if (!Boolean(elements.length)) {
        return;
      }

      const instances = [...elements].map(
        (element) => new ResponsiveImage({ element })
      );

      responsiveImages.current = [...responsiveImages.current, ...instances];
    });

    app.addEventListener("turbo:before-visit", () => {
      if (!Boolean(responsiveImages.current)) {
        return;
      }

      responsiveImages.current.forEach((instance) => {
        instance.destroy();
      });
    });
  },
};
