import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    open: Boolean
  }

  open() {
    this.openValue = true;
  }

  close() {
    this.openValue = false;
  }

  openValueChanged() {
    this.element.setAttribute("aria-expanded", this.openValue);
  }

  toggle() {
    const makeDropdownOpen = !this.openValue;
    if (makeDropdownOpen) {
      this.open();
    } else {
      this.close();
    }
    this.dispatch("toggle", { open: makeDropdownOpen });
  }

  dispatch(event, args = {}) {
    return this.element.dispatchEvent(
      new CustomEvent(event, { bubbles: true, cancelable: true, details: args })
    );
  }
}
