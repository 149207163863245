export * from "./animation";
export * from "./focus-lock";

export const isEconomyEditMode = () =>
  document.documentElement.classList.contains("edit") ||
  document.documentElement.classList.contains("record");

export const interpolate = (min, max, progress) => {
  return progress * (max - min) + min;
};

export const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

export const fetchPageMarkup = (url) => {
  const parsedUrl = editSearchParams(url, (params) => {
    params.set("remote", "1");
  });
  return fetch(parsedUrl, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => res.text())
    .catch((err) => {
      console.error("Could not load the requested page.", err); // eslint-disable-line no-console
    });
};

export const fetchJson = (url) => {
  return fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error("Could not load the requested page.", err); // eslint-disable-line no-console
    });
};

export const editSearchParams = (url, cb) => {
  // The URL interface treats relative URLs as invalid unless we pass in a base.
  const formatted =
    url.startsWith("/") || url.startsWith("?")
      ? new URL(url, window.location.origin)
      : new URL(url);
  const params = new URLSearchParams(formatted.search);
  cb(params, url);
  formatted.search = params.toString();
  return formatted.href;
};

export const getLastItem = (arr) => {
  return arr[arr.length - 1];
};

export const wait = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export function slugify(string) {
  let output = string;
  // Trim whitespace
  output = output.replace(/^\s+|\s+$/g, "");
  // All lowercae
  output = output.toLowerCase();

  // Remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    output = output.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  return output
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes
}

export const loopAround = (val, min, max) => {
  if (val > max) {
    return min;
  }
  if (val < min) {
    return max;
  }
  return val;
};

export const promiseOrTimeout = ({ promise, timeout, result = "resolve" }) => {
  return new Promise((resolve, reject) => {
    let settled = false;
    promise()
      .then((...args) => {
        settled = true;
        resolve(...args);
      })
      .catch((...args) => {
        settled = true;
        reject(...args);
      });
    setTimeout(() => {
      if (settled) {
        return;
      }

      if (result === "resolve") {
        resolve();
      } else {
        reject();
      }
    }, timeout);
  });
};
