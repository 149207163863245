import { Controller } from "@hotwired/stimulus";

export default class NewsletterSignup extends Controller {
  static targets = ["errorMessage", "closeButton", "form", "screen"];
  static values = {
    visible: Boolean,
    screen: String,
    errorMessage: String,
  };

  connect() {
    window.newsletterSignup = this;
  }

  error(message) {
    console.log("Error", event);
    this.errorMessageValue = this.getFormattedErrorMessage(message);
    this.screenValue = "error";
  }

  hide() {
    this.visibleValue = false;
  }

  reset() {
    this.screenValue = "form";
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();

    /*
      NOTE: This assumes jQuery is on the page. Unfortunately, we need to use
      jsonp to get around CORS issues. The fetch API does not support jsonp
      out of the box, so we use jQuery's ajax method instead.
    */
    $.ajax({
      type: "GET",
      url: this.data.get("ajax-action"),
      data: $(this.formTarget).serialize(),
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      error: (error) => this.error(error.message),
      success: (response) => {
        if (response.result === "error") {
          this.error(response.msg);
        } else {
          this.success();
        }
      },
    });
  }

  success() {
    this.screenValue = "success";
  }

  toggleVisibility(event) {
    this.visibleValue = !this.visibleValue;

    if (event.target.getAttribute("aria-controls") === "newsletter-signup") {
      event.target.setAttribute("aria-expanded", this.visibleValue);
    }
  }

  /*
  :::::::::::::
  :: Helpers ::
  :::::::::::::
  */
  getFormattedErrorMessage(errorMessage) {
    if (
      errorMessage.includes("NetworkError") ||
      errorMessage.includes("timeout")
    ) {
      return "Sorry, the server did not respond. Please try again later.";
    }

    if (errorMessage.includes("already subscribed")) {
      return "You're already subscribed!";
    }

    if (errorMessage.includes("invalid email address")) {
      return "Please enter a valid email address.";
    }

    if (errorMessage.includes("too many recent signup requests")) {
      return "You've made too many recent signup requests. Please try again later.";
    }

    return "Sorry, something went wrong. Please try again later.";
  }

  get currentScreen() {
    return this.screenTargets.find(
      (el) => el.dataset.newsletterSignupScreen === this.screenValue
    );
  }

  unsetFixedHeights() {
    this.element.style.removeProperty("height");
    this.screenTargets.forEach((el) => {
      el.style.removeProperty("height");
    });
  }

  /*
  :::::::::::::::::::::::::::
  :: Value change handlers ::
  :::::::::::::::::::::::::::
  */
  errorMessageValueChanged(message) {
    if (!this.hasErrorMessageTarget) {
      return;
    }

    this.errorMessageTarget.textContent = message;
  }

  screenValueChanged(screen) {
    const fixedHeight = this.element.offsetHeight;
    this.screenTargets.forEach((el) => {
      const isCurrentScreen = el.dataset.newsletterSignupScreen === screen;
      el.style.setProperty("display", isCurrentScreen ? "block" : "none");
      el.setAttribute("aria-hidden", !isCurrentScreen);
    });

    if (!this.visibleValue) {
      return;
    }

    const { currentScreen } = this;
    const currentScreenHeight = currentScreen.offsetHeight;
    if (currentScreenHeight !== fixedHeight) {
      const duration = (currentScreenHeight / fixedHeight) * 300;
      this.element
        .animate(
          [
            { height: `${fixedHeight}px` },
            { height: `${currentScreenHeight}px` },
          ],
          {
            duration,
          }
        )
        .finished.then(() => {
          this.element.style.removeProperty("height");
        });
    }
  }

  visibleValueChanged(visible, previousValue) {
    if (typeof previousValue === "undefined" || previousValue === visible) {
      return;
    }
    this.element.setAttribute("aria-hidden", !visible);
    if (visible) {
      this.element.style.setProperty("display", "block");
      const height = this.element.offsetHeight;
      this.element
        .animate([{ height: "0px" }, { height: `${height}px` }], {
          duration: 300,
        })
        .finished.then(() => {
          this.element.style.removeProperty("height");
        });
    } else {
      this.element.style.setProperty("display", "block");
      const height = this.element.offsetHeight;
      this.element
        .animate([{ height: `${height}px` }, { height: "0px" }], {
          duration: 300,
        })
        .finished.then(() => {
          this.element.style.setProperty("display", "none");
        });
    }
  }
}
