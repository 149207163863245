// TODO: This controller should update all hrefs in the page to include the current filter/sort state.
import app from "../recess_app";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["group", "filter", "sorter", "reset"];
  static values = {
    group: String || null,
    filter: Object,
    sort: Object,
  };
  static outlets = ["dropdown-button", "sort-button"];

  initialize() {
    this.sortQueryParams = this.hasSorterTarget
      ? [
          ...new Set(
            this.sorterTargets.map(
              (sorterElement) => sorterElement.dataset.filterPanelQueryParam
            )
          ),
        ]
      : [];
    this.filterQueryParams = this.hasFilterTarget
      ? [
          ...new Set(
            this.filterTargets.map(
              (filterElement) => filterElement.dataset.filterPanelQueryParam
            )
          ),
        ]
      : [];
  }

  /************************************************************************
   * Opening/Closing dropdowns ********************************************
   ************************************************************************/

  // This is the event handler for the dropdown button's "toggle" event.
  // The dropdown button is used to open/close groups of filters.
  // It updates the state's `group` value to describe the current filter group.
  // If the group is already open, it closes it.
  updateVisibleGroup({ params }) {
    const { group } = params;
    if (this.groupValue === group) {
      this.groupValue = null;
    } else {
      this.groupValue = params.group;
    }
  }

  // When the state's `group` value changes, this method is called.
  // It updates the dropdown button to reflect the current state:
  // - the active group is made visible
  // - all other groups are hidden
  // - all other non-active dropdown buttons are closed via the `dropdown-button` outlet
  groupValueChanged(group) {
    // Show the correct panel
    this.groupTargets.forEach((groupElement) => {
      const makeVisible = group === groupElement.dataset.group;
      groupElement.dataset.visible = makeVisible;
    });

    // If there's no filter group, we're done
    if (!Boolean(group)) {
      return;
    }

    // Otherwise if there is a newly activated filter panel, mark all other tabs as inactive
    this.dropdownButtonOutlets.forEach((dropdownButton) => {
      if (group === dropdownButton.element.dataset.filterPanelGroupParam) {
        return;
      }

      dropdownButton.close();
    });
  }

  /************************************************************************
   * Filtering ************************************************************
   ************************************************************************/

  /*

  /*
    This is the event handler for the filter panel's "filter" event.
    It's called when a filter button is clicked.
    It updates the state's `filter` value to describe the current filter
    group and the specific filter applied.
    If the filter is already active, it clears the filter.
  */
  filter(e) {
    // e.preventDefault();

    const query = e.params?.query;
    const value = e.params?.value?.toString();

    if (
      !query ||
      !value ||
      (this.filterValue.query == query && this.filterValue.value == value)
    ) {
      this.filterValue = { query: null, value: null };
    } else {
      this.filterValue = { query, value };
    }
    this.dispatch("filter", {
      detail: {
        query,
        value,
      },
    });
  }

  /*
    When the state's `filter` value changes, this method is called.
    It updates the filter buttons to reflect the current state:
    - only the active filter button is marked as active
    - all other filter buttons are marked as inactive
    - the "reset" button should appear if any filters are active

    This method is also called when the page loads, to ensure that
    the filter buttons are in the correct state.

    Unlike sorters or the dropdown buttons, filters do not use outlets,
    so we just use a simple data attribute to style their "active state" display.
  */
  filterValueChanged() {
    const query = this.filterValue?.query;
    const value = this.filterValue?.value;

    if (this.hasResetTarget) {
      this.resetTarget.dataset.visible = Boolean(query && value).toString();
    }

    this.filterTargets.forEach((filterElement) => {
      const queryParamMatches =
        query === filterElement.dataset.filterPanelQueryParam;
      const valueMatches =
        value === filterElement.dataset.filterPanelValueParam;
      if (queryParamMatches && valueMatches) {
        filterElement.dataset.active = true;
        return;
      }

      filterElement.dataset.active = false;
    });

    if (this.hasSorterTarget && this.filterQueryParams.length > 0) {
      this.sorterTargets.forEach((link) => {
        const linkUrl = new URL(link.href);
        const linkParams = linkUrl.searchParams;

        const currentUrl = new URL(window.location.href);
        const currentUrlParams = currentUrl.searchParams;

        // intersection of current URL params and sorter params
        this.filterQueryParams.forEach((param) => {
          if (!currentUrlParams.has(param)) {
            return;
          }

          linkParams.set(param, currentUrlParams.get(param));
        });

        linkUrl.search = linkParams.toString();
        link.setAttribute("href", linkUrl.toString());
      });
    }
  }

  /************************************************************************
   * Sorting **************************************************************
   ************************************************************************/

  // This is the event handler for the sort button's "sort" event.
  // It's called when a sort button is clicked.
  // It updates the state's `sort` value to describe the current sort:
  // both the query param and the sort order.
  // Some sort orders are directional, while others are "active" or "inactive".
  sort({ params, detail }) {
    const { type, order, active } = detail;

    const query = params.query;
    const value = type === "directional" ? order : active;
    this.sortValue = {
      query,
      value,
    };

    this.dispatch("sort", {
      detail: {
        query,
        value,
      },
    });
  }

  // When the state's `sort` value changes, this method is called.
  // It updates the sort buttons to reflect the current state:
  // - only the active sort button is marked as active
  // - all other sort buttons are marked as inactive
  sortValueChanged(sort) {
    const { query, value } = sort;
    this.sortButtonOutlets.forEach((sortButton) => {
      const queryParamMatches =
        query === sortButton.element.dataset.filterPanelQueryParam;

      if (queryParamMatches) {
        return;
      }

      sortButton.unset();
    });

    if (this.hasFilterTarget && this.sortQueryParams.length > 0) {
      this.filterTargets.forEach((link) => {
        const linkUrl = new URL(link.href);
        const linkParams = linkUrl.searchParams;

        const currentUrl = new URL(window.location.href);
        const currentUrlParams = currentUrl.searchParams;

        // intersection of current URL params and sorter params
        this.sortQueryParams.forEach((param) => {
          if (!currentUrlParams.has(param)) {
            return;
          }

          linkParams.set(param, currentUrlParams.get(param));
        });

        linkUrl.search = linkParams.toString();
        link.setAttribute("href", linkUrl.toString());
      });
    }
  }
}
