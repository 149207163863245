/*
  This little controller allows us to use a button to trigger a Turbo visit,
  like a link click would. We sometimes need this when we want to request a
  page asynchronously, but we don't want to use a link, because we don't want
  the user to know the URL/be able to access it directly.

  Example:

  <button
    data-controller="button-link"
    data-action="click->button-link#click"
    data-url="/some/url"
    data-turbo-action="replace"
    data-turbo-frame="some-frame"
  >
    Click me!
  </button>
*/
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  click(e) {
    const {
      url,
      turboAction: action = null,
      turboFrame: frame = null,
    } = e.currentTarget.dataset;
    Turbo.visit(url, { action, frame });
  }
}
