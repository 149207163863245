// Entry point for the build script in your package.json
import "@hotwired/turbo";
import "./controllers";

import { blockLevelLinks } from "./components/block_level_link";
import { responsiveImages } from "./components/responsive_image";
import { svgs } from "./components/svg";

blockLevelLinks.init();
responsiveImages.init();
svgs.init();
