import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["newsletter-signup"];

  toggleNewsletterSignupVisibility(event) {
    if (!this.hasNewsletterSignupOutlet) {
      return;
    }

    this.newsletterSignupOutlet.toggleVisibility(event);
  }
}
