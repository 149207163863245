import { Controller } from "@hotwired/stimulus";
import focusLock from "../utilities/focus-lock";

// Connects to data-controller="site-nav"
export default class extends Controller {
  static targets = ["toggler", "nav", "search"];
  static values = { open: Boolean };

  /*
  ==========
  Public API
  ==========
  */
  open() {
    this.openValue = true;
  }

  close() {
    this.openValue = false;
  }

  toggle() {
    if (this.openValue) {
      this.close();
    } else {
      this.open();
    }
  }

  /*
  ==============
  Render methods
  ==============
  */
  openValueChanged(open) {
    // Update the aria-expanded attribute on the togglers
    this.togglerTargets.forEach((toggler) => {
      toggler.setAttribute("aria-expanded", open);
    });

    // Update aria-hidden attribute on the site nav
    this.navTarget.setAttribute("aria-hidden", !open);

    // Prevent scrolling the main page
    if (open) {
      document.documentElement.style.setProperty("overflow", "hidden");
    } else {
      document.documentElement.style.removeProperty("overflow");
    }

    // Lock focus inside the site nav
    if (open) {
      this.unlockFocus = focusLock.lock({
        elements: [this.element],
        loopTarget: this.togglerTarget,
      });
    } else if (this.unlockFocus) {
      this.unlockFocus();
    }

    // Disable tabbing into the nav if it's closed
    if (open) {
      this.navTarget.removeAttribute("inert");
    } else {
      this.navTarget.setAttribute("inert", true);
    }

    // Trigger the search input to size itself
    if (this.hasSearchTarget) {
      this.searchTarget.dataset.growingSearchVisibleValue = open;
    }
  }

  /*
  ==============
  Event handlers
  ==============
  */
  handleOutsideClick(e) {
    if (this.element.contains(e.target)) {
      return;
    }

    this.close();
  }
}
