import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="site-nav"
export default class extends Controller {
  static targets = ["sizer", "input", "submit"];
  static values = { visible: Boolean };

  // This allows other elements to trigger the grow method.
  // For example, the parent controller (Site Nav) updates the
  // `data-growing-search-visible-value` attribute and this method is called.
  visibleValueChanged(visible) {
    if (!visible) {
      return;
    }

    this.grow();
  }

  grow() {
    const stringToTest = this.inputTarget.value || "Search";
    this.sizerTarget.textContent = stringToTest;
    const maxWidth = this.element.offsetWidth - this.submitTarget.offsetWidth;
    const sizerWidth = this.sizerTarget.offsetWidth;

    this.inputTarget.style.setProperty(
      "width",
      `clamp(1ch, ${sizerWidth}px, ${maxWidth}px)`
    );
  }
}
