import { Controller } from "@hotwired/stimulus";
import Plyr from "plyr";

export default class extends Controller {
  static values = {
    paused: true,
    poster: true,
    ready: false,
  };
  static targets = [
    "iframeContainer",
    "poster",
    "playPauseButton",
  ];

  connect() {
    window.videos ||= [];
    window.videos.push(this);
    const autoplay = this.element.dataset.autoplay === "true";
    this.player = new Plyr(this.iframeContainerTarget, {
      controls: [],
      autoplay: autoplay,
      muted: autoplay,
      ratio: this.aspectRatio,
    });

    const controller = this;
    this.player.on("ready", () => {
      controller.readyValue = true;
    });
    this.player.on("play", () => {
      controller.pausedValue = false;
    });
    this.player.on("pause", () => {
      controller.pausedValue = true;
    });
  }

  disconnect() {
    window.videos = window.videos.filter((video) => video !== this);
  }

  get aspectRatio() {
    if (this.element.dataset.aspectRatio) {
      // Ideally, look for an aspect ratio passed in via props
      return this.element.dataset.aspectRatio;
    } else if (
      this.hasIframeContainerTarget &&
      this.iframeContainerTarget.dataset?.plyrAspectRatio
    ) {
      // Otherwise, locate a data attribute `data-plyr-aspect-ratio`
      return this.iframeContainerTarget.dataset.plyrAspectRatio;
    } else if (this.hasIframeTarget) {
      const computedStyle = getComputedStyle(this.iframeTarget);
      // Otherwise, look for an aspect ratio applied on the video already
      if (computedStyle["aspect-ratio"]) {
        return computedStyle["aspect-ratio"].replace(" / ", ":");
      } else if (this.iframeTarget.hasAttributes("width", "height")) {
        return `${this.iframeTarget.getAttribute(
          "width"
        )}:${this.iframeTarget.getAttribute("height")}`;
      }
    }
  }

  togglePlayPause() {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  play() {
    this.player.play();
  }

  firstPlay() {
    this.player.volume = 1;
    this.posterValue = false;
  }

  pause() {
    this.player.pause();
  }

  destroyPlayer() {
    if (!this.player) {
      return;
    }

    this.player.destroy();
    this.pausedValue = true;
    this.posterValue = true;
    this.readyValue = false;
  }
}
