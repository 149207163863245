import { Controller } from "@hotwired/stimulus";
import focusLock from "../utilities/focus-lock";

export default class extends Controller {
  static targets = ["backdrop", "close", "content"];

  connect() {
    this.focusReturn = document.activeElement;
    document.documentElement.style.setProperty("overflow", "hidden");
    this.closeTarget.focus();
    this.unlockFocus = focusLock.lock({
      elements: [this.element],
      loopTarget: this.closeTarget
    });
  }

  close() {
    document.documentElement.style.removeProperty("overflow");
    this.element.parentElement.removeAttribute("src");
    this.element.remove();
    if (this.focusReturn) {
      this.focusReturn.focus();
    }
    if (this.unlockFocus) {
      this.unlockFocus();
    }
  }
}
