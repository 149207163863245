export const animateProperty = ({
  element,
  property,
  value,
  duration = "300ms",
  delay = '0ms',
  easing = "ease-out",
}) => {
  return new Promise((resolve, reject) => {
    element.style.setProperty(
      "transition",
      `${property} ${duration} ${easing} ${delay}`
    );
    const endHandler = () => {
      resolve();
      element.removeEventListener("transitionend", endHandler);
    };
    element.addEventListener("transitionend", endHandler);
    element.style.setProperty(property, value);
  });
};

export const fadeOutElement = ({ element, duration, easing }) => {
  // Set pre-transition state
  element.style.setProperty("display", "block");
  element.style.setProperty("opacity", 1);

  // Trigger a reflow so that we can then use a transition
  element.offsetWidth; // eslint-disable-line no-unused-expressions

  return animateProperty({
    property: "opacity",
    value: 0,
    element,
    duration,
    easing,
  }).then(() => {
    element.style.setProperty("display", "none");
  });
};

export const fadeInElement = ({
  element,
  duration,
  easing,
  display = "block",
}) => {
  // Set pre-transition state
  element.style.setProperty("display", display);
  element.style.setProperty("opacity", 0);

  // Trigger a reflow so that we can then use a transition
  element.offsetWidth; // eslint-disable-line no-unused-expressions

  return animateProperty({
    property: "opacity",
    value: 1,
    element,
    duration,
    easing,
  });
};

export const fadeOutElements = ({ elements, ...props }) => {
  const promises = [...elements].map((element) => {
    return fadeOutElement({ element, ...props });
  });
  return Promise.all(promises);
};

export const fadeInElements = ({ elements, ...props }) => {
  const promises = [...elements].map((element) => {
    return fadeInElement({ element, ...props });
  });
  return Promise.all(promises);
};
