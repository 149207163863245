import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    order: String,
    active: Boolean,
  };

  ascend() {
    this.orderValue = "asc";
  }

  descend() {
    this.orderValue = "desc";
  }

  unset() {
    this.orderValue = null;
    this.activeValue = false;
  }

  activate() {
    this.activeValue = true;
  }

  toggle() {
    if (this.data.get("type") === "directional") {
      if (this.orderValue === "asc") {
        this.descend();
      } else {
        this.ascend();
      }
    } else {
      if (this.activeValue) {
        this.unset();
      } else {
        this.activate();
      }
    }

    this.dispatch("sort", {
      detail: {
        type: this.data.get("type"),
        order: this.orderValue,
        active: this.activeValue,
      },
    });
  }
}
